// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// The javascript parts of bootstrap
import 'bootstrap'

// Extra style from the asset pipeline
// import '../stylesheets/application'
import '../plugins/jquery-easing/jquery.easing'
import '../plugins/slick-carousel/slick'
import '../plugins/slick-carousel/slick.css'
import '../plugins/html5-device-mockups/dist/device-mockups.css'

import '../plugins/modernizr/modernizr'

document.addEventListener("turbolinks:load", () => {
  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top - 66)
        }, 1000, "easeInOutExpo");
        return false;
      }
    }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function() {
    $('.navbar-collapse').collapse('hide');
  });

  // Closes navbar dropdown menu when a scroll trigger link is clicked
  $('.dropdown-menu .js-scroll-trigger').click(function() {
    $("#portfolioDropdown").dropdown("toggle");
  });

  // Activate scrollspy to add active class to navbar items on scroll
  $('body').scrollspy({
    target: '#navbar-main',
    offset: 150
  });

  // Collapse Navbar
  var navbarCollapse = function() {
    if ($("#navbar-main").offset().top > 100) {
      $("#navbar-main").addClass("collapsed");
    } else {
      $("#navbar-main").removeClass("collapsed");
    }
  };
  // Collapse now if page is not at top
  navbarCollapse();
  // Collapse the navbar when page is scrolled
  $(window).scroll(navbarCollapse);

  $('.carousel-portfolio').slick({
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    nextArrow: '<div class="slick-prev"><i class="fa fa-angle-right fa-fw"></i></div>',
    prevArrow: '<div class="slick-next"><i class="fa fa-angle-left fa-fw"></i></div>',
  });

  $('.carousel-testimonials').slick({
    infinite: true,
    nextArrow: '<div class="slick-prev"><i class="fa fa-angle-right fa-fw"></i></div>',
    prevArrow: '<div class="slick-next"><i class="fa fa-angle-left fa-fw"></i></div>',
  });

});
